const menu_links = [
  {
    pathname: "about",
    span: "1. About"
  },
  {
    pathname: "sources",
    span: "2. Sources"
  },
  {
    pathname: "news",
    span: "3. News"
  },
  {
    pathname: "who",
    span: "4. Who"
  },
  {
    pathname: "acknowledgments",
    span: "5. Acknowledgments"
  },
  {
    pathname: "contact",
    span: "6. Contact"
  }
]

module.exports = menu_links;
