const config = {
  sheet: "https://sheet.best/api/sheets/b04e3d4a-348d-47f9-8009-2eb454712dd6",
  dbApi: "https://webhooks.mongodb-stitch.com/api/client/v2.0/app/ford-bot-read-donpl/service/http/incoming_webhook/webhook-ford",
  cmsApi: "https://franklin-ford-cms.herokuapp.com/abouts",
  gpt2Endpoint: "35.226.112.179:8880/generator",
  emailJs_service_id: "gmail",
  emailJs_template_id: "ford_gpt2_entries",
  emailJs_user_id: "user_azunZ7YPXk9unATgWX5le"
}

module.exports = config;
