import React, { Component } from "react";
import { Tweet } from 'react-twitter-widgets'
import App from "../../App.css";

class SourcesComponentBody extends Component {

  renderPDF = () => {
    return (
      <a>show PDF</a>
    )
  }
  renderContentMetadata = () => {
    let data = this.props;
    let ele = this.props.ele;

    return (
      <section>
      <h1>Metadata of the source</h1>
      <div className="metadata_source_component">
          <span>{ele.title}</span>
          <span>{ele.author_name}</span>
          <span>{ele.date_year}</span>
          <span>{ele.full_date_if_known}</span>
          <span>{ele.published_in}</span>
          <span>{ele.pages}</span>
          <span>{ele.lieu}</span>
          <span>{ele.file_name_pdf}</span>
          <span>{ele.file_name_text}</span>
          <span>{ele.notes}</span>
          <span>{ele.manuscript_tapuscrit}</span>
          <span>{ele.archive}</span>
      </div>
      </section>
    )
  }


  renderContentPDF = () => {

    let ele = this.props.ele;
    let cloudinaryPDFLink = ele.pdf_on_cloudinary;
    let cloudinaryTxtLink = ele.txt_on_cloudinary;
    let isAllowedOnWebsite = ele.show_only_txt_on_website;

    if(isAllowedOnWebsite === "TRUE"){
      return (
        <section>
        <h1>Original files of the source</h1>
        <div>
          <div className="pdf_source_component">
              {this.renderPDF(ele)}
            <div>
              <a href={cloudinaryTxtLink} target="_blank">
                <span>SEE THE OCR'ED TEXT ONLINE</span>
              </a>
            </div>
          </div>
        </div>
        </section>
      )
    }else{
      return null;
    }
  }

  renderPDF = (ele) => {
    let cloudinaryPDFLink = ele.gsx$pdfoncloudinary.$t;
    let isAllowedOnWebsite = ele.gsx$showonlytxtonwebsite.$t;

    if(isAllowedOnWebsite === "TRUE"){
      return null
    }else{
      return (
        <div>
          <a href={cloudinaryPDFLink} target="_blank">
            <span>SEE THE PDF ONLINE</span>
          </a>
        </div>
      )
    }
  }

  renderTweetIfAny = () => {
    let tweetsData = this.props.tweetsData;
    let tweetsDataLength = tweetsData.length;
    if(!tweetsData || tweetsDataLength === 0){
        return null;
      }else{
        return this.mapThroughTweets(tweetsData)
      }
  }

  mapThroughTweets = (listOfTweets) => {
    let listOfTweetsMaped = listOfTweets.map((ele, index) => {
      let tweetURL = `https://twitter.com/franklinfordbot/status/${ele.entry.dataOfTheTweet.twitter_id_str}`
      return (
        <div className="tweet_maped" key={index}>
          <a href={tweetURL} target="_blank">
             <span>{ele.entry.dataOfTheTweet.twitter_text}</span>
          </a>
        </div>
      )
    })
    return (
      <section>
        <h1>Tweets created from the source</h1>
        {listOfTweetsMaped}
      </section>
    )
  }

  render() {
    if(!this.props){
      return null;
    }
    return (
      <div
        className="source_container_body"
        id={this.props.anchorTagBody}
        style={{display: this.props.display}}>
        {this.renderContentMetadata()}
        {this.renderContentPDF()}
        {this.renderTweetIfAny()}
      </div>
    );
  }
}

export default SourcesComponentBody;
