const mock_data_imgs = {
  backgroundImages: [
    {
      img: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1573080265/ford/f_f_2.svg",
      className: "small"
    },
    {
      img: "ttps://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1573080265/ford/f_f_3.svg",
      className: "small"
    },
    {
      img: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1573080265/ford/f_f_4.svg",
      className: "big"
    },
    {
      img: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1578910716/ford/5-02-02.svg",
      className: "small"
    }
  ]
}

module.exports = mock_data_imgs;
